import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-two-columns-layout',
  styleUrls: ['./two-columns.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-header fixed>
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive *ngIf="!isPortfolio">
        <ng-content select="nb-menu"></ng-content>
        asd
      </nb-sidebar>

      <nb-layout-column class="small">
      </nb-layout-column>

      <nb-layout-column>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <nb-layout-footer fixed *ngIf="!isPortfolio">
        <ngx-footer></ngx-footer>
      </nb-layout-footer>

    </nb-layout>
  `,
})
export class TwoColumnsLayoutComponent implements OnInit {

  constructor(private router:Router){}
  isPortfolio=false;
  ngOnInit(): void {
    if(this.router.url.split('/portfoliopreview/').length>1){
      this.isPortfolio = true;
    } else {
      this.isPortfolio = false;
    }
  }

  }
