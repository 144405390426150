import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AngularFireAuthGuard, redirectUnauthorizedTo, redirectLoggedInTo } from '@angular/fire/auth-guard';
import { NbAuthComponent } from '@nebular/auth';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['auth/login']);
const redirectLoggedInToDash = () => redirectLoggedInTo(['pages/dashboard']);
import {NgxLoginComponent} from './auth/login/login.component';
import {ForgotPasswordComponent} from './auth/forgot-password/forgot-password.component';
/*import {NbRegisterComponent} from './auth/register/register.component';
import {NbRequestPasswordComponent} from './auth/request-password/request-password.component';*/

export const routes: Routes = [
  {
    path: 'pages', 
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
  },
  {
    path: 'auth',
    component: NbAuthComponent,  
    children: [
      {
        path: 'login',
        component: NgxLoginComponent,
        canActivate: [AngularFireAuthGuard], 
        data: { authGuardPipe: redirectLoggedInToDash }
      },{
        path: 'request-password',
        component: ForgotPasswordComponent,
        canActivate: [AngularFireAuthGuard], 
        data: { authGuardPipe: redirectLoggedInToDash }
      }
    ],
  },
  
  /*{
    path: 'register',
    component: NbRegisterComponent, 
    canActivate: [AngularFireAuthGuard], 
    data: { authGuardPipe: redirectLoggedInToDash }
  },
  {
    path: 'request-password',
    component: NbRequestPasswordComponent,
    canActivate: [AngularFireAuthGuard], 
    data: { authGuardPipe: redirectLoggedInToDash }
  },*/
  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages' },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
