import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { ServiceService } from './../../../service/service/service.service';
import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [ { title: 'Profile', icon:'profile' }, { title: 'Log out', icon:'logout' } ];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService: UserData,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService,
              private authService: ServiceService,
              private auth: AngularFireAuth,
              private router: Router,
              private activatedRoute: ActivatedRoute
              ) {
  }
  isPortfolio = false;
  portfolioId='';
  
  async ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;
    this.auth.authState.subscribe(userData => {
      if (userData) {
        this.user = JSON.parse(localStorage.getItem('user'));
        if(this.user.role==1){
          this.user.displayName = this.user.firstName+' '+this.user.lastName;
          this.userMenu.splice(0,1);
        }
        else{
          this.user.displayName = this.user.name;
        }
      }
    })

    if(this.router.url.split('/portfoliopreview/').length>1){
      this.isPortfolio = true;
      this.portfolioId = this.router.url.split('/portfoliopreview/')[1].split('/')[0]
    }
    

    this.menuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'my-context-menu'),
        map(({ item: title })=>title),
      )
      .subscribe((title)=>{
        if(title.icon=='logout'){
          this.authService.SignOut();
        }
        if(title.icon=='profile'){
          let usr=JSON.parse(localStorage.getItem('user'));
          this.router.navigate(['/pages/profile'])
        }
      });

    /*this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = users.nick);*/

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    if(!this.isPortfolio)
    this.menuService.navigateHome();
    else
    this.router.navigate(['pages/portfoliopreview/'+this.portfolioId])
    return false;
  }
}
